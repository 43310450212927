import {required, TextInput, useLogin} from "react-admin";
import {Avatar, Box, Card, Stack} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";
import {LockPersonRounded} from "@mui/icons-material";
import {FormProvider, useForm} from "react-hook-form";
import {validateSpace} from "utils/index";

const LoginPage = () => {
  const login = useLogin();
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm();
  const {setError, clearErrors, handleSubmit} = form;


  const onSubmit = (data: any) => {
    setLoading(true);
    clearErrors();
    login(data)
      .catch((error) => {
        setError('email', {message: error.message});
        setError('password', {message: error.message});
      })
      .finally(() => setLoading(false))
  }

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', height: '100vh',
      backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)'
    }}>
      <Card sx={{p: 3, minWidth: 300, mt: 5, height: 'fit-content'}}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems={'center'} spacing={2}>
              <Avatar>
                <LockPersonRounded/>
              </Avatar>
              <TextInput source={'email'} label={'Email'} validate={[required(), validateSpace]} fullWidth/>
              <TextInput source={'password'} label={'Пароль'} type={'password'} validate={[required(), validateSpace]}
                         fullWidth/>
              <LoadingButton loading={loading} type={'submit'} variant={'contained'} size={'large'} fullWidth>
                Войти
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      </Card>
    </Box>
  )
}

export default LoginPage;
