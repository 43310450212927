import {DateField, useRecordContext} from "react-admin";
import React from "react";

const PublishedDateField = ({label}: { label: string }) => {
  const record = useRecordContext();
  const publishedDate = record?.publishedAt ? new Date(record.publishedAt) : null;
  const currentDate = new Date();


  return (
    <DateField
      sortable={false}
      locales={'ru-RU'}
      label={label}
      emptyText={'-'}
      source={'publishedAt'}
      sx={{color: publishedDate && publishedDate > currentDate ? "red" : 'inherit'}}
    />
  );
};

export default PublishedDateField;
