import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  required,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
  useUpdate
} from "react-admin";
import Modal from "components/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import {validateSpace} from "utils/index";

interface EditSubcategoryModalProps {
  open: boolean;
  onClose: () => void;
  subcategory?: { id: string; title: string };
  category?: { id: string; title: string };
}

const EditSubcategoryModal: React.FC<EditSubcategoryModalProps> = ({
                                                                     open,
                                                                     onClose,
                                                                     subcategory,
                                                                     category,
                                                                   }) => {

  const notify = useNotify();
  const refresh = useRefresh();

  const [create, {isPending: isCreating}] = useCreate();
  const [update, {isPending: isUpdating}] = useUpdate();

  const handleSaveEdit = (data: any) => {
    if (subcategory) {
      update(
        `subcategory/${subcategory.id}`,
        {id: subcategory.id, data: {title: data.title, categoryId: data.category}},
        {
          onSuccess: () => {
            notify("Подкатегория обновлена", {type: "success"});
            refresh();
            onClose();
          },
          onError: () => {
            notify("Ошибка при обновлении подкатегории", {type: "error"});
          },
        }
      )
    } else {
      create(
        'subcategory',
        {data: {title: data.title, categoryId: data.category}},
        {
          onSuccess: () => {
            notify("Подкатегория создана", {type: "success"});
            refresh();
            onClose();
          },
          onError: () => {
            notify("Ошибка при создании подкатегории", {type: "error"});
          },
        }
      )
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Form onSubmit={handleSaveEdit} defaultValues={{category: category?.id, title: subcategory?.title}}>
        <Stack spacing={1}>
          <Typography variant="h6" mb={2}>
            {subcategory?.id ? 'Редактировать подкатегорию' : 'Создать подкатегорию'}
          </Typography>
          <ReferenceInput
            reference={'category'}
            source="category"
            optionText="title"
            alwaysOn
          >
            <AutocompleteInput
              filterToQuery={(searchText) => ({category: searchText})}
              label="Категория" size={'small'} validate={required()}/>
          </ReferenceInput>
          <TextInput
            fullWidth
            source={'title'}
            label="Название подкатегории"
            size={"small"}
            validate={[required(), validateSpace]}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={onClose}>Отмена</Button>
            <LoadingButton loading={isUpdating || isCreating} type={'submit'} variant="contained" color="primary"
                           sx={{ml: 2}}>
              Сохранить
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </Modal>
  );
};

export default EditSubcategoryModal;
