import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, InputAdornment, TextField, Typography} from "@mui/material";
import Modal from "components/Modal";
import CopyButton from "components/buttons/CopyButton";
import {useNotify, useUpdate} from "react-admin";
import {CreateUserResponse} from "resources/users/create/types";
import {useParams} from "react-router-dom";

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({open, onClose}) => {
  const {id} = useParams()
  const [password, setPassword] = useState("");

  const [update, {isPending}] = useUpdate<CreateUserResponse>();
  const notify = useNotify();

  useEffect(() => {
    update(
      `user/${id}/reset-password`,
      {id, data: {}},
      {
        onSuccess: (res) => {
          setPassword(res.password);
          notify('Пароль успешно изменен', {type: "success"});
        },
        onError: () => {
          notify("Ошибка при смене пароля", {type: "error"});
        }
      }
    )
  }, [])

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Typography variant="h6" mb={2}>
          Новый пароль
        </Typography>
        {
          isPending ? <CircularProgress/> : (<TextField
            fullWidth
            label="Пароль"
            value={password}
            disabled
            margin="normal"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyButton copyText={password}/>
                  </InputAdornment>
                ),
              }
            }}
          />)
        }
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Закрыть</Button>
        </Box>
      </>
    </Modal>
  );
};

export default ChangePasswordModal;
