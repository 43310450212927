import {SelectInput, TextInput} from "react-admin";
import React from "react";
import {MATERIAL_TYPE} from "types/index";
import {MATERIAL_TYPE_TITLE} from "constants/index";

const categoryFilter = [
  <TextInput label="Категория" source="category" alwaysOn size={"small"} fullWidth/>,
  <TextInput label="Подкатегория" source="subcategory" alwaysOn size={"small"} fullWidth/>,
  <SelectInput
    size={"small"} fullWidth
    alwaysOn
    label={'Тип'}
    source="type"
    emptyText={'Тип не выбран'}
    sx={{
      mt: 0
    }}
    choices={[
      {id: MATERIAL_TYPE.MATERIAL, name: MATERIAL_TYPE_TITLE[MATERIAL_TYPE.MATERIAL]},
      {id: MATERIAL_TYPE.KNOWLEDGE_BASE, name: MATERIAL_TYPE_TITLE[MATERIAL_TYPE.KNOWLEDGE_BASE]},
    ]}/>
]

export default categoryFilter
