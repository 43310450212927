import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  List,
  ReferenceInput,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar
} from "react-admin";
import * as React from "react";
import FilterTemplate from "components/FilterTemplate";
import {validateDate} from "utils/index";
import useSetTabTitle from "../../../hooks/useSetTabTitle";

const questionListFilters = [
  <DateInput source="createdAt" label={'Дата создания'} alwaysOn size={"small"} fullWidth
             validate={validateDate()}
  />,
  <TextInput source="questionText" alwaysOn label={'Текст вопроса'} size={"small"} fullWidth/>,
  <ReferenceInput
    size={"small"} fullWidth
    reference={'question-category'}
    source="questionCategoryId"
    optionText="title"
    emptyText="Все категории"
    alwaysOn
  >
    <AutocompleteInput
      filterToQuery={(searchText) => ({category: searchText})}
      label="Категория" size={"small"} fullWidth/>
  </ReferenceInput>,
  <ReferenceInput
    reference={'tag'}
    source="tagId"
    optionText="title"
    emptyText="Все теги"
    alwaysOn
  >
    <AutocompleteInput
      filterToQuery={(searchText) => ({title: searchText})}
      label="Тег" size={"small"} fullWidth/>
  </ReferenceInput>,
]

const ListActions = () => (
  <TopToolbar>
    <CreateButton/>
  </TopToolbar>
);


const QuestionList = () => {
  useSetTabTitle("Найдите ответ");

  return (
    <List
      actions={<ListActions/>}
      empty={<p>Вопросы не найдены</p>}
    >
      <FilterTemplate filters={questionListFilters}/>
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" label={'Дата создания'} locales={'ru-RU'} sortable={false}/>
        <TextField source={"questionText"} label={'Текст вопроса'} sortable={false}/>
        <ArrayField source="categories" label={'Категории'} sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="title" size="small"/>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="tags" label={'Теги'} sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="title" size="small"/>
          </SingleFieldList>
        </ArrayField>
        <BooleanField source="publishedAt" label={'Опубликован'} looseValue sortable={false}/>
        <DateField source="publishedAt" label={'Дата публикации'} locales={'ru-RU'} sortable={false}/>
      </Datagrid>
    </List>
  )
}

export default QuestionList
