import {Box, Button, Stack} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useListContext} from "react-admin";
import {FormProvider, useForm} from "react-hook-form";

const FilterTemplate = ({filters}: { filters: any[] }) => {
  const {filterValues, setFilters} = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  const handleSubmit = (data: any) => {
    setFilters(data);
  };

  const onClearFilters = () => {
    setFilters({}, []);
    form.reset({});
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Box sx={{p: 2}}>
          <Grid container columnSpacing={2} rowSpacing={4}>
            {filters.map((filter) => (
              <Grid key={filter.name} size={{xs: 12, md: 3}}>
                {filter}
              </Grid>
            ))}
            <Grid size={{xs: 12}} sx={{justifyContent: 'flex-end', display: 'flex'}}>
              <Stack spacing={2} direction='row'>
                <Button
                  onClick={onClearFilters}
                >
                  Сбросить
                </Button>
                <Button type='submit' variant='contained' color='primary'>
                  Применить
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>

  )
}

export default FilterTemplate
