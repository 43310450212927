import {ResourceNameEnum} from "types/index";
import {Card, CircularProgress, Typography} from "@mui/material";
import {useGetList, useResourceContext} from "react-admin";
import {useParams} from "react-router-dom";
import VersionCard from "components/material/view/history/VersionCard";
import {Material} from "components/material/types";
import {useState} from "react";

interface HistoryTabProps {
  material: Material;
}

const HistoryTab = ({material}: HistoryTabProps) => {
  const {id} = useParams()
  const resource = useResourceContext() as ResourceNameEnum.KnowledgeBase || ResourceNameEnum.Material;
  const {data, isPending} = useGetList(`${resource}/${id}/version/list`);

  const [expandedVersion, setExpandedVersion] = useState<string>('');

  if (isPending) return <CircularProgress/>
  return (
    <Card sx={{p: 2}}>
      {
        data?.length
          ? (data.map(version => <VersionCard key={`version-${version.id}`}
                                              version={version}
                                              material={material} expandedVersion={expandedVersion}
                                              setExpandedVersion={setExpandedVersion}/>))
          : <Typography>Нет версий</Typography>
      }
    </Card>
  )
}

export default HistoryTab
