import {Box, ModalProps} from "@mui/material";
import MuiModal from '@mui/material/Modal'

const Modal = (props: ModalProps) => {
  const {open, onClose, children} = props;
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 2,
        }}
      >
        {children}
      </Box>
    </MuiModal>
  )
}

export default Modal;
