import React from "react";
import {Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import CopyButton from "components/buttons/CopyButton";
import Modal from "components/Modal";

interface ViewCredentialsModalProps {
  open: boolean;
  onClose: () => void;
  email: string;
  password: string;
}

const ViewCredentialsModal: React.FC<ViewCredentialsModalProps> = ({open, onClose, email, password}) => {

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Typography variant="h6" mb={2}>
          Просмотр данных
        </Typography>
        <TextField
          fullWidth
          label="Email"
          value={email}
          disabled
          margin="normal"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <CopyButton copyText={email}/>
                </InputAdornment>
              ),
            }
          }}
        />
        <TextField
          fullWidth
          label="Пароль"
          value={password}
          disabled
          margin="normal"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <CopyButton copyText={password}/>
                </InputAdornment>
              ),
            }
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button variant="contained" onClick={onClose}>
            Закрыть
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default ViewCredentialsModal;
