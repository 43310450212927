import {Card, Stack} from "@mui/material";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  Form,
  ReferenceInput,
  required,
  TextInput,
  useCanAccess,
  useCreate,
  useGetList,
  useNotify,
  useRedirect,
  useRefresh,
  useResourceContext,
  useUpdate
} from "react-admin";
import {MATERIAL_TYPE, ResourceNameEnum} from "types/index";
import * as React from "react";
import {Material} from "components/material/types";
import SubcategoryField from "./SubcategoryField";
import LoadingButton from "@mui/lab/LoadingButton";
import {validateSpace} from "utils/index";

interface MaterialInfoProps {
  material: Material | undefined;
  type: MATERIAL_TYPE;
}

const MaterialInfo = ({material, type}: MaterialInfoProps) => {
  const [create, {isPending: isCreating}] = useCreate()
  const [update, {isPending: isUpdating}] = useUpdate()
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const {data: keyword, isPending} = useGetList('keyword');
  const keywordOptions = keyword ? [...keyword] : [];

  const resource = useResourceContext() as ResourceNameEnum.KnowledgeBase || ResourceNameEnum.Material;

  const {canAccess: canUpdate} = useCanAccess({
    resource,
    action: 'update'
  });


  const onUpdate = (data: any, id: string) => {
    update(
      `${resource}/${id}`,
      {
        id, data: {
          title: data.title,
          description: data.description,
          subcategoryId: data.subcategoryId,
          keywordsInput: data.keywords?.map((keyword: { id: string; title: string }) => {
            const isOldKeyword = Number.isNaN(+keyword.id)
            return ({
              id: isOldKeyword ? keyword.id : undefined,
              title: isOldKeyword ? undefined : keyword.title,
            })
          })
        }
      },
      {
        onSuccess: () => {
          notify(material?.id ? "Материал обновлен" : "Материал создан", {type: "success"});
          if (!material?.id) redirect('show', resource, id)
          refresh();
        },
        onError: () => {
          notify(material?.id ? "Ошибка при обновлении материала" : "Ошибка при создании материала", {type: "error"});
        },
      }
    )
  }
  const onSubmit = (data: any) => {
    if (material) {
      onUpdate(data, material.id)
    } else {
      create(
        resource,
        {data: {}},
        {
          onSuccess: (res) => {
            onUpdate(data, res.id)
          },
          onError: (err) => {
            notify("Ошибка при создании материала", {type: "error"});

          },
        }
      )
    }
  }

  const defaultValues = {
    ...material,
    keywords: material?.keywords?.map(keyword => ({id: keyword.keywordId, title: keyword.keyword.title})) || [],
    category: material?.subcategory?.category?.id
  }

  return (
    <Card sx={{p: 2}}>
      <Form values={defaultValues} onSubmit={onSubmit} disabled={!canUpdate}>
        <Stack spacing={2}>
          <TextInput
            source="title"
            label="Название материала"
            size={'small'}
            validate={[required(), validateSpace]}/>
          <TextInput
            source="description"
            label="Описание материала"
            size={'small'}
            validate={[required(), validateSpace]}
            multiline
          />

          <AutocompleteArrayInput label="Теги"
                                  disabled={!canUpdate}
                                  loading={isPending}
                                  choices={keywordOptions}
                                  source="keywords"
                                  optionText={'title'}
                                  format={(values) => {
                                    return values?.map((v: { id: string | number; title: string }) => v.id)
                                  }}
                                  parse={values => {
                                    return values?.map((id: string | number) => keywordOptions.find(option => option.id === id))
                                  }}
                                  size={'small'}
                                  onCreate={(filter) => {
                                    const newCategory = {id: keywordOptions.length + 1, title: filter}; // присваиваем уникальный id
                                    keywordOptions.push(newCategory);
                                    return newCategory;
                                  }}
          />
          <ReferenceInput
            reference="category"
            source="category"
            filter={{type}}
            alwaysOn
          >
            <AutocompleteInput
              validate={required()}
              label="Категория"
              size="small"
              filterToQuery={(searchText) => ({category: searchText})}
              disabled={!canUpdate}
            />
          </ReferenceInput>
          <SubcategoryField disabled={!canUpdate}/>
          <DateInput source="createdAt" label="Дата создания" disabled/>
          <DateInput source="updatedAt" label="Дата редактирования" disabled/>
          <DateInput source="publishedAt" label="Дата публикации" disabled/>
          <LoadingButton loading={isCreating || isUpdating} type={'submit'} variant={'contained'} disabled={!canUpdate}>
            Сохранить
          </LoadingButton>
        </Stack>
      </Form>
    </Card>
  )
}

export default MaterialInfo
