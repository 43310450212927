import {useListContext} from "react-admin";
import {Box, CircularProgress, Typography} from "@mui/material";
import {MaterialCategoryListItem} from "resources/materialCategoryList/types";
import {ComponentType} from "react";

interface CategoryGridProps {
  Card: ComponentType<{ category: {id: string; title: string}; }>;
}

const CategoryGrid = ({Card}: CategoryGridProps) => {
  const {data, isLoading} = useListContext();

  if (isLoading) {
    return <CircularProgress/>;
  }


  if (!data || data.length === 0 || isLoading) {
    return (
      <Box p={2}>
        {
          isLoading ? <CircularProgress/>
            :
            <Typography variant={'body2'}>
              Список категорий пуст
            </Typography>
        }
      </Box>
    )
  }
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {data.map((category: MaterialCategoryListItem) => (
        <Card key={category.id} category={category}/>
      ))}
    </Box>
  );
};

export default CategoryGrid;
