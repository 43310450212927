import {Button} from "@mui/material";
import {useState} from "react";
import EditQuestionCategoryModal from "../modals/EditQuestionCategoryModal";


const CreateCategoryButton = () => {
  const [openCreateCategoryCategoryModal, setOpenCreateCategoryCategoryModal] = useState<boolean>(false);
  const handleOpenCreateCategoryModal = () => setOpenCreateCategoryCategoryModal(true);
  const handleCloseCreateCategoryModal = () => setOpenCreateCategoryCategoryModal(false);

  return (
    <>
      <Button onClick={handleOpenCreateCategoryModal}>
        Создать категорию
      </Button>
      {openCreateCategoryCategoryModal && <EditQuestionCategoryModal isOpen={openCreateCategoryCategoryModal}
                                                                     onClose={handleCloseCreateCategoryModal}/>}
    </>
  )
}

export default CreateCategoryButton;
