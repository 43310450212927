import Modal from "components/Modal";
import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import {
  DateInput,
  Form,
  RadioButtonGroupInput,
  required,
  useNotify,
  useRefresh,
  useResourceContext,
  useUpdate
} from "react-admin";
import {useParams} from "react-router-dom";
import {useWatch} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import {validateDate} from "utils/index";
import dayjs from "dayjs";

interface PublishMaterialModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PublishDateInput = () => {
  const selectedOption = useWatch({name: "when"});

  if (selectedOption === 'later') {
    return <DateInput source="publishedAt" label="Дата публикации"
                      validate={[required(), validateDate(dayjs().add(1, 'day'), null)]}/>
  }
  return null
}

const PublishMaterialModal = ({isOpen, onClose}: PublishMaterialModalProps) => {
  const {id} = useParams()
  const resource = useResourceContext();
  const [update, {isPending}] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handlePublish = (data: any) => {
    update(
      `${resource}/${id}/publish`,
      {id, data: {publishedAt: data.when === 'later' ? new Date(data.publishedAt).toISOString() : undefined}},
      {
        onSuccess: () => {
          notify("Материал опубликован", {type: "success"});
          refresh();
          onClose()
        },
        onError: (error, variables, context) => {
          notify("Ошибка при публикации материала", {type: "error"});
        },
      }
    )
  }
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h6" mb={2}>
          Опубликовать материал
        </Typography>
        <Form onSubmit={handlePublish} defaultValues={{when: "now"}}>
          <Stack spacing={2}>
            <RadioButtonGroupInput
              source="when"
              label="Выберите опцию"
              choices={[
                {id: "now", name: "Опубликовать сейчас"},
                {id: "later", name: "Отложенная публикация"},
              ]}
            />
            <PublishDateInput/>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={onClose}>Отмена</Button>
              <LoadingButton loading={isPending} variant="contained" color="primary" sx={{ml: 2}} type="submit">
                Опубликовать
              </LoadingButton>
            </Box>
          </Stack>
        </Form>
      </Stack>
    </Modal>
  )
}

export default PublishMaterialModal
