import {AutocompleteInput, DateInput, ReferenceInput, TextInput} from "react-admin";
import {MATERIAL_TYPE} from "types/index";
import React from "react";
import {validateDate} from "utils/index";

const materialFilters = [
  <DateInput
    source="createdAt"
    label="Дата создания"
    alwaysOn
    size={"small"}
    fullWidth
    validate={validateDate()}
  />,
  <TextInput source="title" label="Название материала" alwaysOn size={"small"} fullWidth/>,
  <ReferenceInput source="categoryId" reference="category" alwaysOn filter={{type: MATERIAL_TYPE.KNOWLEDGE_BASE}}>
    <AutocompleteInput
      optionText="title"
      label="Категория"
      size={'small'} fullWidth
      filterToQuery={(searchText) => ({category: searchText})}
    />
  </ReferenceInput>,
  <ReferenceInput
    source="subcategoryId"
    reference="subcategory"
    label="Подкатегория"
    alwaysOn
  >
    <AutocompleteInput
      filterToQuery={(searchText) => ({title: searchText})}
      optionText="title" label="Подкатегория" size={'small'} fullWidth/>
  </ReferenceInput>,
]

export default materialFilters
