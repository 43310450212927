import {DateInput, Form} from "react-admin";
import * as React from "react";
import {Question} from "resources/question/view/types";
import {Card, Stack} from "@mui/material";

const AnswerInfo = ({question}: { question: Question | undefined }) => {
  return (
    <Card sx={{p: 2}}>
      <Form defaultValues={question}>
        <Stack spacing={2}>
          <DateInput source="createdAt" label="Дата создания" disabled/>
          <DateInput source="updatedAt" label="Дата редактирования" disabled/>
          <DateInput source="publishedAt" label="Дата публикации" disabled/>
        </Stack>
      </Form>
    </Card>
  )
}

export default AnswerInfo
