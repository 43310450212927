export default {
  fontFamily: 'Pragmatica, Arial, sans-serif',
  p1: {
    fontFamily: 'Pragmatica, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '140%',
    fontStyle: 'normal'
  },
}
