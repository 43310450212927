import {TopToolbar,} from 'react-admin';
import CreateCategoryButton from "resources/questionCategory/list/components/CreateCategoryButton";
import CreateTagButton from "resources/questionCategory/list/components/CreateTagButton";


const QuestionCategoryActions = () => {
  return (
    <TopToolbar>
      <CreateTagButton/>
      <CreateCategoryButton/>
    </TopToolbar>
  )
}

export default QuestionCategoryActions;
