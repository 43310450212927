import Modal from "components/Modal";
import {MATERIAL_TYPE} from "types/index";
import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import {Material} from "components/material/types";
import {useNotify, useRefresh, useUpdate} from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";

interface HideMaterialModalProps {
  isOpen: boolean;
  onClose: () => void;
  material: Material;
}

const HideMaterialModal = ({isOpen, onClose, material}: HideMaterialModalProps) => {
  const title = material.title;
  const id = material.id;
  const resource = material.type === MATERIAL_TYPE.MATERIAL ? 'material' : 'knowledge-base';

  const [update, {isPending}] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleHide = () => {
    update(
      `${resource}/${id}/hide`,
      {id, data: {}},
      {
        onSuccess: () => {
          notify("Материал скрыт", {type: "success"});
          refresh();
          onClose()
        },
        onError: (error, variables, context) => {
          notify("Ошибка при скрытии материала", {type: "error"});
        },
      }
    )
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h6" mb={2}>
          Скрыть материал
        </Typography>
        <Typography>
          {`Вы уверены, что хотите скрыть материл ${title}`}
        </Typography>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <LoadingButton loading={isPending} variant="contained" color="primary" sx={{ml: 2}} onClick={handleHide}>
            Скрыть
          </LoadingButton>
        </Box>
      </Stack>
    </Modal>
  )
}

export default HideMaterialModal;
