import {Box, Chip, CircularProgress, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Editor from "components/material/view/info/Editor";
import MaterialInfo from "components/material/view/info/MaterialInfo";
import {useCanAccess, useGetOne, useResourceContext} from 'react-admin'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {SyntheticEvent, useState} from "react";
import {MATERIAL_TYPE, ResourceNameEnum} from "types/index";
import {useParams} from "react-router-dom";
import HistoryTab from "components/material/view/history/HistoryTab";
import MaterialActions from "components/material/view/MaterialActions";
import FileAttachmentForm from "components/material/view/info/FileAttachmentForm";
import useSetTabTitle from "hooks/useSetTabTitle";

type Tab = 'info' | 'history'


const MaterialViewTemplate = ({type}: { type: MATERIAL_TYPE }) => {
  const params = useParams();
  const {id} = params;

  const [value, setValue] = useState<Tab>('info');
  const resource = useResourceContext() as ResourceNameEnum.KnowledgeBase || ResourceNameEnum.Material;

  const {canAccess: canUpdate} = useCanAccess({
    resource,
    action: 'update'
  });

  const {canAccess: canManage} = useCanAccess({
    resource,
    action: 'manage'
  });

  const handleChange = (event: SyntheticEvent, newValue: Tab) => {
    setValue(newValue);
  };

  const {data: material, isLoading} = useGetOne(resource!, {id});
  useSetTabTitle(`Материал ${material?.title || ''}`);

  if (isLoading) return <CircularProgress/>;

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        <Grid size={{xs: 12}}>
          <Stack justifyContent={'space-between'} direction={'row'}>
            <Typography variant={'h4'}>
              {`Материал ${material?.title || ''}`}
            </Typography>
            {!!material && (
              <Stack direction={'row'} alignItems={'center'}>
                <Chip label={material.isHidden ? 'Материал скрыт' : 'Материал опубликован'} size={'small'}
                      color={material.isHidden ? 'error' : 'success'}/>
                {canManage && (<MaterialActions material={material}/>)}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid size={{xs: 12, md: 8}}>
          <TabContext value={value}>
            <TabList onChange={handleChange}>
              <Tab label="Общая информация" value="info"/>
              {!!material && (<Tab label="История версий" value="history"/>)}
            </TabList>
            <TabPanel value={'info'}>
              <Stack spacing={2}>
                <Editor disabled={!material || !canUpdate} resource={resource}/>
                {!!material && <FileAttachmentForm/>}
              </Stack>
            </TabPanel>
            <TabPanel value={'history'}>
              <HistoryTab material={material}/>
            </TabPanel>
          </TabContext>
        </Grid>
        <Grid size={{xs: 12, md: 4}}>
          <MaterialInfo material={material} type={type}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MaterialViewTemplate
