import {Button} from "@mui/material";
import {useState} from "react";
import EditQuestionTagModal from "../modals/EditQuestionTagModal";


interface CreateTagButtonProps {
  category?: { id: string; title: string };
}


const CreateTagButton = ({category}: CreateTagButtonProps) => {
  const [openCreateTagModal, setOpenCreateTagModal] = useState<boolean>(false);
  const handleOpenCreateTagModal = () => setOpenCreateTagModal(true);
  const handleCloseCreateTagModal = () => setOpenCreateTagModal(false);
  return (
    <>
      <Button onClick={handleOpenCreateTagModal} sx={{width: 'fit-content'}}>
        Создать тег
      </Button>
      {openCreateTagModal &&
          <EditQuestionTagModal open={openCreateTagModal} onClose={handleCloseCreateTagModal} category={category}/>}
    </>
  )
}

export default CreateTagButton;
