import React from "react";
import {BooleanField, CreateButton, Datagrid, DateField, List, TextField, TextInput, TopToolbar} from "react-admin";
import FilterTemplate from "components/FilterTemplate";
import useSetTabTitle from "hooks/useSetTabTitle";

const userFilters = [
  <TextInput label="ФИО пользователя" source="name" alwaysOn size={'small'} fullWidth/>,
  <TextInput label="Email" source="email" alwaysOn size={'small'} fullWidth/>
];

const ListActions = () => (
  <TopToolbar>
    <CreateButton/>
  </TopToolbar>
);


const UserList = () => {
  useSetTabTitle('Пользователи')
  return (
    <List
      title={"Пользователи"}
      actions={<ListActions/>}
      perPage={10}>
      <FilterTemplate filters={userFilters}/>
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" label="Дата создания" sortable={false} locales={'ru-RU'}/>
        <TextField source="name" label="ФИО" sortable={false}/>
        <TextField source="email" label="email" sortable={false}/>
        <BooleanField source="is_blocked" label="Заблокирован" sortable={false}/>
      </Datagrid>
    </List>
  )
};

export default UserList;
