// identityState.ts
let forceRefreshIdentity = false;

const getForceRefreshIdentity = () => forceRefreshIdentity;

const setForceRefreshIdentity = (value: boolean) => {
  forceRefreshIdentity = value;
};

export {getForceRefreshIdentity, setForceRefreshIdentity};
