import {Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography} from "@mui/material";
import {Material, MaterialVersion} from "components/material/types";
import dayjs from "dayjs";
import Text from "components/Text";
import {ResourceNameEnum} from "types/index";
import {Dispatch, SetStateAction, useState} from "react";
import SetVersionModal from "components/material/view/modals/SetVersionModal";
import {useCanAccess, useResourceContext} from "react-admin";

interface VersionCardProps {
  version: MaterialVersion;
  material: Material;
  expandedVersion: string;
  setExpandedVersion: Dispatch<SetStateAction<string>>;
}

const VersionCard = ({version, material, expandedVersion, setExpandedVersion}: VersionCardProps) => {
  const resource = useResourceContext() as ResourceNameEnum.KnowledgeBase || ResourceNameEnum.Material;

  const {canAccess: canUpdate} = useCanAccess({
    resource,
    action: 'update'
  });

  const [openSetVersionModal, setOpenSetVersionModal] = useState(false);
  const handleOpenSetVersionModal = () => setOpenSetVersionModal(true);
  const handleCloseSetVersionModal = () => setOpenSetVersionModal(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedVersion(isExpanded ? panel : '');
    };

  const isExpanded = version.id === expandedVersion

  return (
    <>
      <Accordion disableGutters expanded={isExpanded} onChange={handleChange(version.id)}>
        <AccordionSummary>
          <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}} alignItems={'center'}>
            <Typography variant={'h6'}>{`Версия ${version.order}`}</Typography>
            <Typography variant={'body2'}>{dayjs(version.createdAt).format('DD.MM.YYYY HH:mm')}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {
            isExpanded && (
              <Stack spacing={2}>
                <Text content={version.text}/>
                {
                  canUpdate && (
                    <Button variant={"contained"} sx={{maxWidth: 'fit-content'}} onClick={handleOpenSetVersionModal}>
                      Восстановить версию
                    </Button>
                  )
                }
              </Stack>
            )
          }
        </AccordionDetails>
      </Accordion>
      {openSetVersionModal && (
        <SetVersionModal isOpen={openSetVersionModal} onClose={handleCloseSetVersionModal} version={version}
                         material={material}/>
      )}
    </>
  )
}

export default VersionCard;
