import React from "react";
import EditCategoryModal from "../modals/EditCategoryModal";
import SubcategoryCard from "resources/materialCategoryList/list/components/SubcategoryCard";
import CategoryCardTemplate from "components/category/CategoryCardTemplate";
import {MATERIAL_TYPE} from "types/index";
import CreateSubcategoryButton from "resources/materialCategoryList/list/components/CreateSubcategoryButton";

const CategoryCard = ({category}: { category: { id: string; title: string; type?: MATERIAL_TYPE } }) => {
  return (
    <CategoryCardTemplate
      category={category}
      resource={'subcategory'}
      EditModal={EditCategoryModal}
      ItemCard={SubcategoryCard}
      CrateSubentityButton={CreateSubcategoryButton}
    />
  );
};

export default CategoryCard;
