import { AuthProvider } from 'react-admin';
import {
  checkAccess,
  fetchAndCacheUserData,
  getTokens,
  removeTokens,
  setTokens
} from './authService';
import {PermissionAction, ResourceName} from "types/index";
import {httpClient} from "./dataProvider";

const apiUrl = process.env.REACT_APP_API_URL;

export type PermissionItem = {
  id: string;
  title: string;
  action: PermissionAction;
};

const authProvider: AuthProvider = {
  login: async (params) => {
    const {email, password} = params;
    return httpClient(`${apiUrl}auth/login`, {
      method: 'POST',
      body: JSON.stringify({email, password}),
      headers: new Headers({'Content-Type': 'application/json'})
    })
      .then(({json}) => {
        setTokens(json);
        return Promise.resolve();
      })
      .catch((err) => {
        if (err?.message === 'Unauthorized') {
          throw new Error('Неверная почта или пароль');
        } else {
          throw new Error(err?.message);
        }
      });
  },
  logout: () => {
    removeTokens();
    return Promise.resolve();
  },
  checkAuth: () => {
    const {accessToken} = getTokens();
    if (accessToken) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  checkError: (params) => {
    const {status} = params;
    const {accessToken} = getTokens();

    if (status === 401 && !accessToken) {
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getIdentity: async () => {
    const {accessToken} = getTokens();
    if (!accessToken) return Promise.reject();
    try {
      const userData = await fetchAndCacheUserData();
      return Promise.resolve({ id: userData.id, fullName: userData.name });
    } catch (error) {
      return Promise.reject();
    }
  },

  canAccess: async ({resource, action}) => {
    const {accessToken} = getTokens();
    if (!accessToken) return Promise.reject();
    try {
      const userData = await fetchAndCacheUserData();
      const userPermissions = userData?.permissions.map((permission: PermissionItem) => permission.action) || [];
      return Promise.resolve(checkAccess(resource as ResourceName, userPermissions, action));
    } catch (error) {
      return Promise.reject();
    }
  },
  getPermissions: async () => {
    const {accessToken} = getTokens();
    if (!accessToken) return Promise.reject();
    try {
      const userData = await fetchAndCacheUserData();
      return userData?.permissions.map((permission) => permission.action);
    } catch (error) {
      return Promise.reject();
    }
  },
};

export default authProvider;
