import {IconButton} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React from "react";
import {useNotify} from "react-admin";

const CopyButton = ({copyText}: { copyText: string }) => {
  const notify = useNotify();
  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    notify("Данные скопированы", {type: "success"})
  };
  return (
    <IconButton onClick={handleCopy}>
      <ContentCopyIcon />
    </IconButton>
  )
}

export default CopyButton
