import {FC} from "react";
import {useInput, Validator} from "react-admin";
import {Box} from "@mui/material";
import RichEditor from "components/RichEditorControl/RichEditor";

type EditorControlProps = {
  name: string;
  rules?: Validator | Validator[];
  disabled?: boolean;
};

const RichEditorControl: FC<EditorControlProps> = ({name, rules, disabled}) => {
  const {
    field,
    fieldState: {error},
  } = useInput({source: name, validate: rules});
  return (
    <Box sx={{position: 'relative'}}>
      <RichEditor onChange={field.onChange} error={!!error} value={field.value || ''} disabled={disabled}/>
    </Box>
  );
};

export default RichEditorControl
