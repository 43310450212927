import {DataProvider, fetchUtils, GetManyParams} from 'react-admin';
import {stringify} from 'query-string';
import qs from 'qs';
import {getTokens} from "./authService";

export const apiUrl = process.env.REACT_APP_API_URL;

export const httpClient = (url: string, options = {} as any) => {
  if (!options?.headers) {
    options.headers = new Headers();
  }
  const {accessToken} = getTokens();
  options.headers.set('Authorization', `JWT ${accessToken}`);
  return fetchUtils.fetchJson(url, options);

};

const dataProvider: DataProvider = {
  getList: (resource, params) => {
    const page = params.pagination?.page || 1;
    const perPage = params.pagination?.perPage || 10;

    const filter = {...params.filter};

    if (filter.createdAt) {
      const date = new Date(filter.createdAt);
      filter['createdAtFrom'] = new Date(date.setHours(0, 0, 0, 0)).toISOString();
      filter['createdAtTo'] = new Date(date.setHours(23, 59, 59, 999)).toISOString();
      delete filter.createdAt; // Удаляем исходное поле
    }


    const query = {
      ...Object.entries(filter || {}).reduce((acc, [key, value]) => {
        acc[`filter[${key}]`] = value;
        return acc;
      }, {} as Record<string, any>),
      'pagination[page]': page - 1,
      'pagination[limit]': perPage,
    };

    const url = `${apiUrl}${resource}?${stringify(query)}`;

    return httpClient(url).then(({headers, json}) => {
      return {
        data: json.data,
        total: json.totalCount,
      };
    });
  },

  get: (resource: any) =>
    httpClient(`${apiUrl}${resource}`).then(({json}) => ({
      data: json,
    })),

  getOne: (resource, params) =>
    httpClient(`${apiUrl}${resource}/${params.id}`).then(({json}) => ({
      data: json,
    })),

  getMany: (resource: string, params: GetManyParams) => {
    const query = {
      filter: {ids: params.ids}
    };
    const url = `${apiUrl}${resource}?${qs.stringify(query)}`;
    return httpClient(url).then(({json}) => json);
  },

  getManyReference: (resource, params) => {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({...params.filter, [params.target]: params.id}),
    };
    const url = `${apiUrl}${resource}?${stringify(query)}`;
    return httpClient(url).then(({headers, json}) => ({
      data: json,
      total: parseInt(headers.get('content-range')?.split('/').pop() || '0', 10),
    }));
  },

  update: (resource, params) => {
    let data;
    if (resource.includes('file')) {
      data = params.data;
    } else {
      data = JSON.stringify(params.data)
    }
    return httpClient(`${apiUrl}${resource}`, {
      method: 'POST',
      body: data,
    }).then(({json}) => ({data: json}))
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    return httpClient(`${apiUrl}${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: json}));
  },

  create: (resource, params) => {
    let data;
    if (resource.includes('file')) {
      data = params.data;
    } else {
      data = JSON.stringify(params.data)
    }
    return httpClient(`${apiUrl}${resource}`, {
      method: 'POST',
      body: data,
    }).then(({json}) => {
      return {data: {...params.data, ...json, id: json.id}}
    })
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({json}) => ({data: json})),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({id: params.ids}),
    };
    return httpClient(`${apiUrl}${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({json}) => ({data: json}));
  },
};

export default dataProvider;
