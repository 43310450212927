import { Editor, FileLoader, UploadResponse } from 'ckeditor5';
import  {apiUrl, httpClient} from "config/dataProvider";
import {getTokens} from "config/authService";

class ImageUploadAdapter {
  private readonly loader: FileLoader;

  constructor(loader: FileLoader) {
    this.loader = loader;
  }

  public upload(): Promise<UploadResponse> {
    return this.loader.file.then(
      (file: File | null) =>
        new Promise((resolve, reject) => {
          ImageUploadAdapter.uploadFile(resolve, reject, file);
        }),
    );
  }

  static async uploadFile(resolve: (value: UploadResponse) => void, reject: (reason?: any) => void, file: File | null) {
    if (!file) {
      reject('No file found');
      return;
    }

    const {accessToken} = getTokens();

    if (!accessToken) {
      reject('Ошибка авторизации при загрузке файла в материал');
      return;
    }

    try {

      const formData = new FormData();
      formData.append("file", file);
       httpClient(`${apiUrl}file`, {
        method: 'POST',
        body: formData
      }).then(({json}) => {
        console.log(json)
         if (json && json.url) {
           resolve({
             default: json.url,
           });
         } else {
           reject('Ошибка загрузки файла в материал');
         }
       })


    } catch (error) {
      reject(error);
    }
  }
}

export default function ImageUploadAdapterPlugin(editor: Editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new ImageUploadAdapter(loader);
}
