import MaterialViewTemplate from "components/material/view/MaterialViewTemplate";
import {MATERIAL_TYPE} from "types/index";
import Edit from "components/custom/Edit";
import {Create} from "react-admin";
import useSetTabTitle from "hooks/useSetTabTitle";

export const MaterialEdit = () => {
  return (
    <Edit>
      <MaterialViewTemplate type={MATERIAL_TYPE.MATERIAL}/>
    </Edit>
  )
}

export const MaterialCreate = () => {
  useSetTabTitle('Создание материала');
  return (
    <Create title={'Создание материала'}>
      <MaterialViewTemplate type={MATERIAL_TYPE.MATERIAL}/>
    </Create>
  )
}
