import {AutocompleteInput, ReferenceInput, required} from "react-admin";
import * as React from "react";
import {useWatch} from "react-hook-form";

const SubcategoryField = ({disabled}: { disabled: boolean }) => {
  const categoryId = useWatch({name: 'category'})

  return (
    <ReferenceInput
      filter={{categoryId}}
      source="subcategoryId"
      reference={'subcategory'}
      optionText="title"
      alwaysOn
    >
      <AutocompleteInput
        disabled={!categoryId || disabled}
        filterToQuery={(searchText) => ({title: searchText})}
        validate={required()}
        label="Подкатегория" size={'small'}/>
    </ReferenceInput>
  )
}

export default SubcategoryField
