import Modal from "components/Modal";
import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import {useNotify, useRefresh, useUpdate} from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";
import {Question} from "resources/question/view/types";

interface HideMaterialModalProps {
  isOpen: boolean;
  onClose: () => void;
  question: Question;
}

const HideQuestionModal = ({isOpen, onClose, question}: HideMaterialModalProps) => {
  const id = question.id;
  const isHidden = question.isHidden;

  const [update, {isPending}] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleHide = () => {
    update(
      `question/${id}/publish-or-hide`,
      {id, data: {}},
      {
        onSuccess: () => {
          notify("Ответ скрыт", {type: "success"});
          refresh();
          onClose()
        },
        onError: (error, variables, context) => {
          notify("Ошибка при скрытии ответа", {type: "error"});
        },
      }
    )
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h6" mb={2}>
          {isHidden ? 'Опубликовать вопрос' : 'Скрыть вопрос'}
        </Typography>
        <Typography>
          {isHidden ? 'Вы уверены, что хотите опубликовать вопрос' : `Вы уверены, что хотите скрыть вопрос?`}
        </Typography>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <LoadingButton loading={isPending} variant="contained" color="primary" sx={{ml: 2}} onClick={handleHide}>
            {isHidden ? 'Опубликовать' : 'Скрыть'}
          </LoadingButton>
        </Box>
      </Stack>
    </Modal>
  )
}

export default HideQuestionModal;
