import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockUserModal from "./BlockUserModal";
import ChangePasswordModal from "./ChangePasswordModal";
import {User} from "resources/users/create/types";

interface UserMenuProps {
  user: User;
}

const UserMenu: React.FC<UserMenuProps> = ({user}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isBlockModalOpen, setBlockModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBlockToggle = () => {
    setBlockModalOpen(true);
    handleMenuClose();
  };

  const handleChangePassword = () => {
    setChangePasswordModalOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleBlockToggle}>
          {user.is_blocked ? "Разблокировать" : "Заблокировать"}
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>Сменить пароль</MenuItem>
      </Menu>

      {isBlockModalOpen && (<BlockUserModal
        open={isBlockModalOpen}
        onClose={() => setBlockModalOpen(false)}
        user={user}
      />)}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          open={isChangePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
        />
      )}
    </>
  );
};

export default UserMenu;
