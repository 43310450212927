import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Material} from "components/material/types";
import PublishMaterialModal from "components/material/view/modals/PublishMaterialModal";
import HideMaterialModal from "components/material/view/modals/HideMaterialModal";
import DeleteMaterialModal from "components/material/view/modals/DeleteMaterialModal";

interface MaterialActionsProps {
  material: Material;
}

const MaterialActions = ({material}: MaterialActionsProps) => {

  const [openPublishModal, setOpenPublishModal] = useState<boolean>(false);
  const [openHideModal, setOpenHideModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOption1 = () => {
    if (material?.isHidden) setOpenPublishModal(true);
    else setOpenHideModal(true);
    handleCloseMenu();
  };

  const handleOption2 = () => {
    setOpenDeleteModal(true);
    handleCloseMenu();
  };

  const onClosePublishModal = () => setOpenPublishModal(false);
  const onCloseHideModal = () => setOpenHideModal(false);
  const onCloseDeleteModal = () => setOpenDeleteModal(false);


  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleOption1}>{material?.isHidden ? 'Опубликовать материал' : 'Скрыть материал'}</MenuItem>
        <MenuItem onClick={handleOption2}>Удалить материал</MenuItem>
      </Menu>

      {openPublishModal && (
        <PublishMaterialModal isOpen={openPublishModal} onClose={onClosePublishModal}/>)}
      {openHideModal && (<HideMaterialModal isOpen={openHideModal} onClose={onCloseHideModal} material={material}/>)}
      {openDeleteModal && (
        <DeleteMaterialModal isOpen={openDeleteModal} onClose={onCloseDeleteModal} material={material}/>)}
    </>
  );
};

export default MaterialActions;
