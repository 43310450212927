import React, {useEffect, useState} from "react";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {
  CheckboxGroupInput,
  Form,
  useDataProvider,
  useGetIdentity,
  useNotify,
  usePermissions,
  useRefresh,
  useUpdate
} from "react-admin";
import {useParams} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {Permission} from "types/index";
import {setForceRefreshIdentity} from "config/state";

interface UserPermissionsProps {
  userPermissions: Permission[];
}

const UserPermissions: React.FC<UserPermissionsProps> = ({userPermissions}) => {
  const dataProvider = useDataProvider();
  const params = useParams();
  const {id} = params;
  const [loading, setLoading] = useState(false);

  const {refetch: refetchPermissions} = usePermissions();
  const {data: currentUser} = useGetIdentity();

  const notify = useNotify();
  const refresh = useRefresh();

  const [update, {isPending}] = useUpdate();

  const [allPermissions, setAllPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        setLoading(true);
        const {data} = await dataProvider.get("permission");
        setAllPermissions(data);
      } catch (error) {
        console.error("Error fetching permissions", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [dataProvider]);

  const defaultValues = userPermissions.map((permission) => permission.id);

  const onSubmit = async (data: any) => {
    update(
      `user/${id}/permissions`,
      {
        id,
        data: {permissionsIds: data.permissions},
      },
      {
        onSuccess: () => {
          if (id === currentUser?.id) {
            setForceRefreshIdentity(true);
            refetchPermissions();
          }
          refresh();
          notify('Права пользователя обновлены', {type: "success"});
        },
        onError: () => {
          notify('Ошибка обновления прав пользователя', {type: "error"});
        }
      }
    )
  };

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Права пользователя
      </Typography>

      {
        loading ? <CircularProgress/>
          : (
            <Form values={{permissions: defaultValues}} onSubmit={onSubmit} disabled={!id}>
              <Stack spacing={0.5} alignItems={"flex-start"}>
                <CheckboxGroupInput
                  row={false}
                  title={''}
                  source="permissions"
                  choices={allPermissions}
                  optionValue={'id'}
                  optionText={'title'}
                  sx={{
                    '& .MuiFormLabel-root': {display: 'none'}
                  }}
                />
                <LoadingButton loading={isPending} variant="contained" color="primary" type={"submit"} disabled={!id}>
                  Сохранить
                </LoadingButton>
              </Stack>
            </Form>
          )
      }
    </Box>
  );
};

export default UserPermissions;
