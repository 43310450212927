import {Components, Theme} from "@mui/material";

export default {
  MuiAppBar: {
    styleOverrides: {
      root: ({theme}) => ({
        backgroundColor: theme.palette.primary.main,
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      colorPrimary: ({theme}) => ({
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
    },
  },
} as Components<Theme>;
