import dayjs, {Dayjs} from "dayjs";

type DateParam = string | Dayjs | null

const validateDate = (minDate: DateParam = '1900-01-01', maxDate: DateParam = dayjs()) => (value: string) => {
  if (!value) return value;
  const date = dayjs(value);

  const isValid = date.isValid();
  if (!isValid) return 'Неверный формат даты';
  if (minDate) {
    const minDateString = typeof minDate === 'string' ? minDate : minDate.format('YYYY-MM-DD');
    const isBefore = date.isBefore(dayjs(minDateString)) || new Date(value) < new Date(minDateString);
    if (isBefore) return `Дата не может быть раньше ${dayjs(minDateString).format('DD.MM.YYYY')}`;
  }
  if (maxDate) {
    const maxDateString = typeof maxDate === 'string' ? maxDate : maxDate.format('YYYY-MM-DD');
    const isAfter = date.isAfter(maxDate);
    if (isAfter) return `Нельзя выбрать дату позднее ${dayjs(maxDateString).format('DD.MM.YYYY')}`;
  }
  return '';
}

const validateEmail = (value: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) return undefined;
  if (!emailRegex.test(value)) {
    return 'Неверный формат email';
  }
  return undefined;
};

const validateSpace = (value: string) => {
  if (value && !value.trim()) return 'ra.validation.required';
  return undefined
}


export {validateDate, validateEmail, validateSpace};
