import React from "react";
import {
  addRefreshAuthToAuthProvider,
  addRefreshAuthToDataProvider,
  Admin,
  Resource,
  TranslationMessages
} from "react-admin";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';

import UserList from "resources/users/list/UserList";
import {UserCreate, UserEdit} from "resources/users/create/UserPage";
import MaterialCategoryList from "resources/materialCategoryList/list/MaterialCategoryList";
import theme from "./theme";
import authProvider from "./config/authProvider";
import dataProvider from "./config/dataProvider";
import KnowledgeBaseList from "resources/knowledgeBase/list";
import MaterialList from "resources/material/list";
import {MaterialCreate, MaterialEdit} from "resources/material/view";
import 'assets/style.css';
import QuestionCategoryList from "resources/questionCategory/list/QuestionCategoryList";
import {Article, Category, LibraryBooks, People, QuestionAnswer, Quiz} from "@mui/icons-material";
import QuestionList from "resources/question/list";
import {KnowledgeBaseCreate, KnowledgeBaseEdit} from "resources/knowledgeBase/view";
import {QuestionCreate, QuestionEdit} from "resources/question/view";
import LoginPage from "components/LoginPage";
import {refreshAuth} from "./config/authService";

const i18nProvider = polyglotI18nProvider(() => ({
  ra: {
    ...russianMessages.ra,
    page: {
      ...russianMessages.ra.page,
      access_denied: 'Нет доступа'
    },
    message: {
      ...russianMessages.ra.message,
      access_denied: 'Недостаточно прав для доступа к ресурсу'
    },
    navigation: {
      ...russianMessages.ra.navigation,
      no_filtered_results: 'Нет результатов, удовлетворяющих фильтрам',
      clear_filters: 'Очистить фильтры'
    }
  }
} as TranslationMessages), 'ru');


const App = () => (
  <Admin authProvider={addRefreshAuthToAuthProvider(authProvider, refreshAuth)}
         dataProvider={addRefreshAuthToDataProvider(dataProvider, refreshAuth)}
         i18nProvider={i18nProvider}
         loginPage={LoginPage}
         theme={theme}>

    <Resource
      name="knowledge-base"
      list={KnowledgeBaseList}
      options={{label: "База знаний"}}
      icon={LibraryBooks}
      show={KnowledgeBaseEdit}
      edit={KnowledgeBaseEdit}
      create={KnowledgeBaseCreate}/>
    <Resource name="material"
              list={MaterialList}
              options={{label: "Полезные материалы"}}
              icon={Article}
              create={MaterialCreate}
              show={MaterialEdit}
              edit={MaterialEdit}
    />
    <Resource name="material-category" list={MaterialCategoryList} options={{label: "Категории материалов"}}
              icon={Category}/>
    <Resource name="question"
              list={QuestionList}
              options={{label: "Найдите ответ"}}
              icon={QuestionAnswer}
              show={QuestionEdit}
              create={QuestionCreate}
              edit={QuestionEdit}
    />
    <Resource name="question-category" list={QuestionCategoryList} options={{label: "Категории ответов"}} icon={Quiz}/>
    <Resource
      options={{label: "Пользователи"}}
      name="user"
      list={UserList}
      edit={UserEdit}
      show={UserEdit}
      create={UserCreate}
      icon={People}
    />
  </Admin>
);

export default App;
