import {httpClient} from "./dataProvider";
import {PermissionAction, ResourceName, User} from "types/index";
import {jwtDecode, JwtPayload} from "jwt-decode";
import cookies from "js-cookie";
import {getForceRefreshIdentity, setForceRefreshIdentity} from "./state";

const apiUrl = process.env.REACT_APP_API_URL;

const accessTokenName = 'access-token';
const refreshTokenName = 'refresh-token';
const domain = window.location.hostname;

export const saveUserData = (userData: User) => {
  try {
    const jsonData = JSON.stringify(userData);
    const encodedData = encodeURIComponent(jsonData);
    localStorage.setItem('user', encodedData);
  } catch (error) {
    console.error('Error saving user data:', error);
  }
};
export const loadUserData = (): User | null => {
  const encodedData = localStorage.getItem('user');
  if (!encodedData) return null;
  try {
    return JSON.parse(decodeURIComponent(encodedData)); // Декодируем из Base64
  } catch {
    return null;
  }
};

export let userDataPromise: Promise<User> | null = null;
export const fetchAndCacheUserData = async (): Promise<User> => {
  const isForceUpdate = getForceRefreshIdentity()
  if (!userDataPromise || isForceUpdate) {
    userDataPromise = (async () => {
      setForceRefreshIdentity(false);
      const userData = await getUserData();
      saveUserData(userData);
      return userData;
    })();
  }

  return userDataPromise;
};

export const refreshAuth = () => {
  const {accessToken, refreshToken} = getTokens();
  if (accessToken && refreshToken) {
    const decodedToken: JwtPayload = jwtDecode(accessToken || '');
    if (decodedToken.exp! < new Date().getTime() / 1000) {
      httpClient(`${process.env.REACT_APP_API_URL}auth/refresh`, {
        method: 'POST',
        body: JSON.stringify({refreshToken}),
        headers: new Headers({authorization: `JWT ${accessToken}`})
      })
        .then(({json}) => {
          setTokens(json);
        })
        .catch((e) => Promise.reject(e));
    }
  }
  return Promise.resolve();
};

export const getTokens = () => ({
  accessToken: cookies.get(accessTokenName),
  refreshToken: cookies.get(refreshTokenName)
});
export const setTokens = (data: any) => {
  cookies.set(accessTokenName, data?.accessToken, {domain});
  cookies.set(refreshTokenName, data?.refreshToken, {domain});
};
export const removeTokens = () => {
  cookies.remove(accessTokenName, {domain});
  cookies.remove(refreshTokenName, {domain});
  localStorage.removeItem('user');
  userDataPromise = null;
};


export const getUserData = async () => {
  try {
    const response = await httpClient(`${apiUrl}auth/me`);
    saveUserData(response.json as User);
    return response.json
  } catch (error) {
    throw new Error('Unable to fetch user data');
  }
};

export const checkAccess = (resource: ResourceName, userPermissions: PermissionAction[], action: string): boolean => {
  if (resource === 'user') {
    return userPermissions.includes(PermissionAction.UserAdministration)
  }
  if (resource === 'knowledge-base') {
    if (action === 'create' || action === 'update') {
      return userPermissions.includes(PermissionAction.KnowledgeBaseUpdate)
    }
    if (action === 'manage') {
      return userPermissions.includes(PermissionAction.KnowledgeBaseManagement)
    }
    return !!userPermissions.find(action =>
      action === PermissionAction.KnowledgeBaseManagement || action === PermissionAction.KnowledgeBaseUpdate
    )
  }
  if (resource === 'material') {
    if (action === 'create' || action === 'update') {
      return userPermissions.includes(PermissionAction.MaterialUpdate)
    }
    if (action === 'manage') {
      return userPermissions.includes(PermissionAction.MaterialManagement)
    }
    return !!userPermissions.find(action =>
      action === PermissionAction.MaterialManagement || action === PermissionAction.MaterialUpdate
    )
  }
  if (resource === 'material-category' || resource === 'category') {
    return !!userPermissions.find(action =>
      action === PermissionAction.MaterialManagement || action === PermissionAction.MaterialUpdate
      || action === PermissionAction.KnowledgeBaseManagement || action === PermissionAction.KnowledgeBaseUpdate
    )
  }
  if (resource === 'question') {
    if (action === 'create' || action === 'update') {
      return userPermissions.includes(PermissionAction.QuestionUpdate)
    }
    if (action === 'manage') {
      return userPermissions.includes(PermissionAction.QuestionManagement)
    }
    return !!userPermissions.find(action =>
      action === PermissionAction.QuestionManagement || action === PermissionAction.QuestionUpdate
    )
  }
  if (resource === 'question-category') {
    return !!userPermissions.find(action =>
      action === PermissionAction.QuestionManagement || action === PermissionAction.QuestionUpdate
    )
  }
  return false;

}
