import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import Modal from "components/Modal";
import {Material} from "components/material/types";
import {MATERIAL_TYPE} from "types/index";
import {useDelete, useNotify, useRedirect, useRefresh} from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";

interface DeleteMaterialModalProps {
  isOpen: boolean;
  onClose: () => void;
  material: Material;
}

const DeleteMaterialModal = ({isOpen, onClose, material}: DeleteMaterialModalProps) => {
  const title = material.title;
  const id = material.id;
  const resource = material.type === MATERIAL_TYPE.MATERIAL ? 'material' : 'knowledge-base';

  const [deleteOne, {isPending}] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect()

  const handleDelete = () => {
    deleteOne(
      `${resource}`,
      {id},
      {
        onSuccess: () => {
          notify("Материал удален", {type: "success"});
          refresh();
          onClose()
          redirect('list', resource)
        },
        onError: (error, variables, context) => {
          notify("Ошибка при удалении материала", {type: "error"});
        },
      }
    )
  }


  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h6" mb={2}>
          Удалить материал
        </Typography>
        <Typography>
          {`Вы уверены, что хотите удалить материл ${title}`}
        </Typography>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <LoadingButton loading={isPending} variant="contained" color="primary" sx={{ml: 2}} onClick={handleDelete}>
            Удалить
          </LoadingButton>
        </Box>
      </Stack>
    </Modal>
  )
}

export default DeleteMaterialModal
