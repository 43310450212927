import {SxProps, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {memo} from "react";
import {IFRAME_TEMPLATES} from "constants/index";

export interface ITextProps {
  content: string;
  sx?: SxProps;
}

const StyledTypography = styled(Typography)(({theme}) => ({
  '& .full-width iframe': {
    width: '100%'
  },
  [theme.breakpoints.down('md')]: {
    '& .embed:not(.full-width) iframe': {
      width: '100%',
      aspectRatio: 16 / 9
    },
  },
  '& a': {wordBreak: 'break-all', textDecoration: 'underline'},
  '& .image': {
    '& > figcaption': {
      display: 'table-caption',
      captionSide: 'bottom',
      wordBreak: 'break-word',
      outlineOffset: -1,
      backgroundColor: 'transparent'
    },
  },
  '& ul, & ol': {
    margin: 0,
    marginLeft: 2,
    padding: 0,
    '& > li': {
      mb: 2,
    },
  },
}));

const Text = memo(({content, sx}: ITextProps) => {
  const createIframe = (content: string) => {
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = content;

    const oembedElements = tempContainer.querySelectorAll("oembed[url]");

    oembedElements.forEach((oembed) => {
      const url = oembed.getAttribute("url");
      const figure = oembed.closest("figure");

      if (figure && url) {
        for (const template of IFRAME_TEMPLATES) {
          const match = url.match(template.url as RegExp);
          if (match) {
            const iframeHtml = template.html(match);

            figure.className = `embed media ck-widget${template.name === 'yandexMaps' || template.name === 'yandexForms' ? ' full-width' : ''}`;

            figure.setAttribute("contenteditable", "false");

            figure.innerHTML = `
            <div class="ck-media__wrapper" data-oembed-url="${url}">
              ${iframeHtml}
            </div>
          `;
            break;
          }
        }
      }
    });

    return tempContainer.innerHTML;
  };


  return (
    <StyledTypography
      variant={'p1'}
      className={'ck-content'}
      sx={{
        ...sx,
      }}
      dangerouslySetInnerHTML={{
        __html: createIframe(content?.replaceAll('color:#000000;', '')),
      }}
    />
  )
})

export default Text;
