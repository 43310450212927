import MaterialListTemplate from "components/material/list/MaterialListTemplate";
import useSetTabTitle from "hooks/useSetTabTitle";

const KnowledgeBaseList = () => {
  useSetTabTitle("База знаний");
  return (
    <MaterialListTemplate/>
  )
};

export default KnowledgeBaseList;
