import {Box, Chip, CircularProgress, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {Create, useCanAccess, useGetOne} from "react-admin";
import {useParams} from "react-router-dom";
import QuestionActions from "resources/question/view/components/QuestionActions";
import AnswerForm from "resources/question/view/components/AnswerForm";
import AnswerInfo from "resources/question/view/components/AnswerInfo";
import Edit from "components/custom/Edit";
import useSetTabTitle from "../../../hooks/useSetTabTitle";

const QuestionPage = () => {
  const params = useParams();
  const {id} = params;
  const {data: question, isLoading} = useGetOne('question', {id});

  const {canAccess: canManage} = useCanAccess({
    action: 'manage',
    resource: 'question',
  });

  useSetTabTitle("Вопрос");

  if (isLoading) return <CircularProgress/>;

  return (
    <Box sx={{py: 2}}>
      <Grid container spacing={2}>
        <Grid size={{xs: 12}}>
          <Stack justifyContent={'space-between'} direction={'row'}>
            <Typography variant={'h4'}>
              Вопрос
            </Typography>
            {question && (
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Chip label={question.isHidden ? 'Вопрос скрыт' : 'Вопрос опубликован'} size={'small'}
                      color={question.isHidden ? 'error' : 'success'}/>
                {canManage && <QuestionActions question={question}/>}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid size={{xs: 12, md: 8}}>
          <AnswerForm question={question}/>
        </Grid>
        <Grid size={{xs: 12, md: 4}}>
          <AnswerInfo question={question}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export const QuestionEdit = () => {
  return (
    <Edit title={'Найдите ответ'}>
      <QuestionPage/>
    </Edit>
  )
}

export const QuestionCreate = () => {
  return (
    <Create title={'Создать ответ'}>
      <QuestionPage/>
    </Create>
  )
}
