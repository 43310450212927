import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {Form, required, TextInput, useCreate, useNotify, useRefresh, useUpdate} from "react-admin";
import Modal from "components/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import {validateSpace} from "utils/index";

interface EditCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  category?: any;
}

const EditQuestionCategoryModal = ({isOpen, onClose, category}: EditCategoryModalProps) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const [create, {isPending: isCreating}] = useCreate();
  const [update, {isPending: isUpdating}] = useUpdate();

  const handleSaveEdit = (data: any) => {
    if (category) {
      update(
        `question-category/${category.id}`,
        {id: category.id, data: {...category, title: data.title}},
        {
          onSuccess: () => {
            notify("Категория обновлена", {type: "success"});
            refresh();
            onClose();
          },
          onError: () => {
            notify("Ошибка при обновлении категории", {type: "error"});
          },
        }
      );
    } else {
      create(
        'question-category',
        {data: {title: data.title}},
        {
          onSuccess: () => {
            notify("Категория создана", {type: "success"});
            refresh();
            onClose();
          },
          onError: () => {
            notify("Ошибка при создании категории", {type: "error"});
          },
        }
      )
    }

  };

  const title = category ? 'Редактировать категорию' : "Создать категорию"


  return (
    <Modal open={isOpen} onClose={onClose}>
      <Form onSubmit={handleSaveEdit} defaultValues={category}>
        <Typography variant="h6" mb={2}>
          {title}
        </Typography>
        <TextInput
          fullWidth
          source={'title'}
          label="Название категории"
          size={"small"}
          validate={[required(), validateSpace]}
        />
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <LoadingButton loading={isUpdating || isCreating} type={'submit'} variant="contained" color="primary"
                         sx={{ml: 2}}>
            Сохранить
          </LoadingButton>
        </Box>
      </Form>
    </Modal>
  )
}

export default EditQuestionCategoryModal
