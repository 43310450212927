import {Button} from "@mui/material";
import EditCategoryModal from "resources/materialCategoryList/list/modals/EditCategoryModal";
import {useState} from "react";


const CategoryActions = () => {
  const [openCreateCategoryCategoryModal, setOpenCreateCategoryCategoryModal] = useState<boolean>(false);
  const handleOpenCreateCategoryModal = () => setOpenCreateCategoryCategoryModal(true);
  const handleCloseCreateCategoryModal = () => setOpenCreateCategoryCategoryModal(false);


  return (
    <>
      <Button onClick={handleOpenCreateCategoryModal}>
        Создать категорию
      </Button>
      {openCreateCategoryCategoryModal &&
          <EditCategoryModal isOpen={openCreateCategoryCategoryModal} onClose={handleCloseCreateCategoryModal}/>}
    </>
  )
}

export default CategoryActions;
