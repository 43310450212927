import {BooleanField, CreateButton, Datagrid, DateField, List, Pagination, TextField, TopToolbar} from "react-admin";
import React from "react";
import materialFilters from "components/material/list/materialFilters";
import PublishedDateField from "components/material/list/PublishedDateField";
import FilterTemplate from "components/FilterTemplate";

const ListActions = () => (
  <TopToolbar>
    <CreateButton/>
  </TopToolbar>
);

const MaterialListTemplate = () => {
  return (
    <List
      actions={<ListActions/>}
      pagination={<Pagination/>}
    >
      <FilterTemplate filters={materialFilters}/>
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" label="Дата создания" sortable={false}/>
        <TextField source="title" label="Название материала" emptyText={'-'} sortable={false}/>
        <TextField source="subcategory.category.title" label="Категория" emptyText={'-'} sortable={false}/>
        <TextField source="subcategory.title" label="Подкатегория" emptyText={'-'}
                   sortable={false}/>
        <BooleanField source="publishedAt" label={'Опубликован'} looseValue sortable={false}/>
        <PublishedDateField label={'Дата публикации'}/>
      </Datagrid>
    </List>

  )
}

export default MaterialListTemplate;
