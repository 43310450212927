import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {useDelete, useNotify, useRefresh} from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "components/Modal";

interface DeleteCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  entity: { id: string; title: string };
  resource: 'category' | "question-category" | "subcategory" | "tag";
  entityText: {
    title: string;
    success: string;
    error: string;
  }
}

const DeleteEntityModal = ({isOpen, entity, onClose, resource, entityText}: DeleteCategoryModalProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [deleteOne, {isPending}] = useDelete();

  const handleConfirmDelete = () => {
    deleteOne(
      resource,
      {id: entity.id},
      {
        onSuccess: () => {
          notify(entityText.success, {type: "success"});
          refresh();
          onClose()
        },
        onError: () => {
          notify(entityText.error, {type: "error"});
        },
      }
    );
  };


  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box>
        <Typography variant="h6" mb={2}>
          {entityText.title}
        </Typography>
        <Typography>Вы уверены, что хотите удалить "{entity.title}"?</Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <LoadingButton
            loading={isPending}
            variant="contained"
            color="error"
            sx={{ml: 2}} onClick={handleConfirmDelete}>
            Удалить
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteEntityModal
