import MaterialViewTemplate from "components/material/view/MaterialViewTemplate";
import {MATERIAL_TYPE} from "types/index";
import {Create} from 'react-admin'
import Edit from "components/custom/Edit";
import useSetTabTitle from "hooks/useSetTabTitle";

export const KnowledgeBaseEdit = () => {
  return (
    <Edit actions={false}>
      <MaterialViewTemplate type={MATERIAL_TYPE.KNOWLEDGE_BASE}/>
    </Edit>
  )
}

export const KnowledgeBaseCreate = () => {
  useSetTabTitle("Создание материала");

  return (
    <Create title={"Создание материала"}>
      <MaterialViewTemplate type={MATERIAL_TYPE.KNOWLEDGE_BASE}/>
    </Create>
  )
}

