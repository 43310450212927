import {List, TextInput} from "react-admin";
import CategoryGrid from "components/category/CategoryGrid";
import QuestionCategoryActions from "./components/QuestionCategoryActions";
import React from "react";
import QuestionCategoryCard from "./components/QuestionCategoryCard";
import FilterTemplate from "components/FilterTemplate";
import useSetTabTitle from "../../../hooks/useSetTabTitle";

const categoryFilter = [
  <TextInput label="Категория" source="category" alwaysOn size={"small"} fullWidth/>,
  <TextInput label="Тег" source="tag" alwaysOn size={"small"} fullWidth/>
]

const QuestionCategoryList = () => {
  useSetTabTitle("Категории ответов");

  return (
    <List
      empty={<p>Категории ответов не найдены</p>}
      title="Категории ответов"
      actions={<QuestionCategoryActions/>}
    >
      <FilterTemplate filters={categoryFilter}/>
      <CategoryGrid Card={QuestionCategoryCard}/>
    </List>
  )
};

export default QuestionCategoryList
