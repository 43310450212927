import React from "react";
import EditQuestionCategoryModal from "../modals/EditQuestionCategoryModal";
import CategoryCardTemplate from "components/category/CategoryCardTemplate";
import TagCard from "resources/questionCategory/list/components/TagCard";
import CreateTagButton from "resources/questionCategory/list/components/CreateTagButton";

const QuestionCategoryCard = ({category}: { category: { id: string; title: string; } }) => {
  return (
    <CategoryCardTemplate
      category={category}
      resource={'tag'}
      EditModal={EditQuestionCategoryModal}
      ItemCard={TagCard}
      CrateSubentityButton={CreateTagButton}

    />
  )
};

export default QuestionCategoryCard;
