import React, {ComponentType, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardActions,
  Chip,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useGetList} from "react-admin";
import DeleteEntityModal from "components/category/DeleteEntityModal";
import {MATERIAL_TYPE_TITLE} from "../../constants";
import {MATERIAL_TYPE} from "types/index";

interface CategoryCardTemplateProps {
  category: { id: string; title: string; type?: MATERIAL_TYPE };
  resource: 'tag' | 'subcategory'
  EditModal: ComponentType<{
    isOpen: boolean;
    onClose: () => void;
    category: { id: string; title: string; type?: MATERIAL_TYPE }
  }>;
  ItemCard: ComponentType<{
    item: { id: string; title: string; };
    category: { id: string; title: string; type?: MATERIAL_TYPE }
  }>;
  CrateSubentityButton: ComponentType<{ category?: { id: string; title: string }; }>
}

const CategoryCardTemplate = ({
                                category,
                                EditModal,
                                ItemCard,
                                resource,
                                CrateSubentityButton
                              }: CategoryCardTemplateProps) => {
  const {data} = useGetList(
    resource,
    {
      pagination: {page: 0, perPage: 1000},
      filter: resource === 'subcategory' ? {categoryId: category.id} : {questionCategoryId: category.id},
    },
  );

  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleToggleAccordion = () => setExpanded(!expanded);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleEdit = () => {
    setEditModalOpen(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
    handleMenuClose();
  };

  const handleCloseEditModal = () => setEditModalOpen(false);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  return (
    <Accordion expanded={expanded} onChange={handleToggleAccordion} disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{m: 0}}>
        <Stack>
          <Typography variant="h6">{category?.title}</Typography>
          {"type" in category && category?.type && (
            <Chip
              sx={{width: 'fit-content'}}
              size="small"
              label={MATERIAL_TYPE_TITLE[category?.type]}
            />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <CardActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={handleMenuOpen}>Управление категорией</Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleEdit}>Редактировать категорию</MenuItem>
            <MenuItem onClick={handleDelete}>Удалить категорию</MenuItem>
          </Menu>
        </CardActions>
        <Box display="flex" flexDirection="column" gap={2}>
          {
            data?.length
              ? data?.map((item) => (
                <ItemCard key={item.id} item={item} category={category}/>
              ))
              : (
                <Typography>
                  {resource === 'tag' ? "Нет тегов" : "Нет подкатегорий"}
                </Typography>
              )
          }
          <CrateSubentityButton category={category} />
        </Box>
      </AccordionDetails>

      {
        isEditModalOpen && (
          <EditModal isOpen={isEditModalOpen} onClose={handleCloseEditModal} category={category}/>
        )
      }
      {
        isDeleteModalOpen && (
          <DeleteEntityModal
            entityText={{
              title: "Удалить категорию",
              success: "Категория удалена",
              error: "Ошибка при удалении категории"
            }}
            resource={resource === 'tag' ? 'question-category' : 'category'}
            isOpen={isDeleteModalOpen}
            onClose={handleCloseDeleteModal}
            entity={category}
          />
        )
      }
    </Accordion>
  );
};

export default CategoryCardTemplate;
