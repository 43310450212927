import React, {useState} from "react";
import {Button, Card, IconButton, Menu, MenuItem, Stack, Typography,} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditQuestionTagModal from "../modals/EditQuestionTagModal";
import DeleteEntityModal from "components/category/DeleteEntityModal";
import {Link} from "react-admin";

const TagCard = ({item, category}: {
  item: { id: string; title: string };
  category: { id: string; title: string }
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleEdit = () => {
    setEditModalOpen(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
    handleMenuClose();
  };


  return (
    <Card sx={{px: 2, py: 1.5}}>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Stack alignItems={'flex-start'}>
          <Typography variant={'h6'}>{item.title}</Typography>
          <Button
            component={Link}
            to={`/question/create?categoryId=${category.id}&tagId=${item.id}`}
          >
            Создать вопрос
          </Button>
        </Stack>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon/>
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleEdit}>Редактировать</MenuItem>
          <MenuItem onClick={handleDelete}>Удалить</MenuItem>
        </Menu>
      </Stack>

      <EditQuestionTagModal
        open={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        tag={item}
        category={category}
      />

      <DeleteEntityModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        entity={item}
        entityText={{
          title: "Удалить тег",
          success: "Тег удален",
          error: "Ошибка при удалении тега"
        }}
        resource={'tag'}
      />
    </Card>
  );
};

export default TagCard;
