import {TopToolbar,} from 'react-admin';
import CreateSubcategoryButton from "resources/materialCategoryList/list/components/CreateSubcategoryButton";
import CreateCategoryButton from "resources/materialCategoryList/list/components/CreateCategoryButton";


const CategoryActions = () => {
  return (
    <TopToolbar>
      <CreateSubcategoryButton />
      <CreateCategoryButton />
    </TopToolbar>
  )
}

export default CategoryActions;
