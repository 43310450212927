import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import Modal from "components/Modal";
import {useDelete, useNotify, useRedirect, useRefresh} from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";
import {Question} from "resources/question/view/types";

interface DeleteMaterialModalProps {
  isOpen: boolean;
  onClose: () => void;
  question: Question;
}

const DeleteQuestionModal = ({isOpen, onClose, question}: DeleteMaterialModalProps) => {
  const id = question.id;

  const [deleteOne, {isPending}] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect()

  const handleDelete = () => {
    deleteOne(
      `question`,
      {id},
      {
        onSuccess: () => {
          notify("Ответ удален", {type: "success"});
          refresh();
          onClose()
          redirect('list', 'question')
        },
        onError: (error, variables, context) => {
          notify("Ошибка при удалении ответа", {type: "error"});
        },
      }
    )
  }


  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h6" mb={2}>
          Удалить ответ
        </Typography>
        <Typography>
          {`Вы уверены, что хотите удалить ответ?`}
        </Typography>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <LoadingButton loading={isPending} variant="contained" color="primary" sx={{ml: 2}} onClick={handleDelete}>
            Удалить
          </LoadingButton>
        </Box>
      </Stack>
    </Modal>
  )
}

export default DeleteQuestionModal
