import {MATERIAL_TYPE} from "types/index";
import {ArrayOrItem} from "ckeditor5";

export const MATERIAL_TYPE_TITLE = {
  [MATERIAL_TYPE.KNOWLEDGE_BASE]: 'База знаний',
  [MATERIAL_TYPE.MATERIAL]: 'Материал'
}

export const IFRAME_TEMPLATES: {
  name: string;
  url: ArrayOrItem<RegExp>;
  html: (match: RegExpMatchArray) => string;
}[] = [
  {
    name: 'yandexMaps',
    url: /^https:\/\/yandex\.ru\/maps\/(-\/[\w-]+)/,
    html: match => {
      const mapId = match[1];
      return `<iframe src="https://yandex.ru/map-widget/v1/${mapId}" width="600" height="400" frameborder="0" allowfullscreen></iframe>`;
    }
  },
  {
    name: 'yandexForms',
    url: /^https:\/\/forms\.yandex\.ru\/[^\s]+/,
    html: match => {
      const formUrl = `${match[0]}?iframe=1`; // Добавляем параметр iframe=1
      const formId = match[0].split('/').pop()?.split('?')[0]; // Извлечение идентификатора формы
      return `<iframe src="${formUrl}" frameborder="0" name="ya-form-${formId}" width="650" height="400"></iframe>`;
    }
  },
  {
    name: 'vkVideo',
    url: /^https:\/\/vkvideo\.ru\/video([0-9\-]+)_([0-9]+)/,
    html: match => {
      const oid = match[1]; // Извлечение oid из URL
      const id = match[2];  // Извлечение id из URL
      return `<iframe src="https://vkvideo.ru/video_ext.php?oid=${oid}&id=${id}&hd=3" width="640" height="360" allow="encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>`;
    }
  },
  {
    name: 'vkComVideo',
    url: /^https:\/\/vk\.com\/video([0-9\-]+)_([0-9]+)/,
    html: match => {
      const oid = match[1]; // Извлечение oid из URL
      const id = match[2];  // Извлечение id из URL
      return `<iframe src="https://vk.com/video_ext.php?oid=${oid}&id=${id}&hd=3" width="640" height="360" allow="encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>`;
    }
  },
  {
    name: 'rutubeVideo',
    url: /^https:\/\/rutube\.ru\/video\/(?:private\/)?([a-z0-9]+)(?:\/|\?)(.*)$/,
    html: match => {
      const videoId = match[1]; // Извлечение идентификатора видео
      const params = match[2] ? `?${match[2]}` : ''; // Извлечение параметров, если они есть
      return `<iframe width="720" height="405" src="https://rutube.ru/play/embed/${videoId}/${params}" frameBorder="0" allow="clipboard-write;" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>`;
    }
  },
]
