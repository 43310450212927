import {Form, useDataProvider, useNotify, useRefresh, useUpdate} from "react-admin";
import RichEditorControl from "components/RichEditorControl";
import {CircularProgress, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {memo, useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";

interface EditorProps {
  disabled: boolean;
  resource: 'material' | 'knowledge-base'
}

const Editor = memo(({disabled, resource}: EditorProps) => {
  const params = useParams();
  const {id} = params;
  const [update, {isPending}] = useUpdate();

  const notify = useNotify();
  const refresh = useRefresh();

  const dataProvider = useDataProvider();
  const [versionText, setVersionText] = useState()

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        setLoading(true)
        const response = await dataProvider.get(resource === 'knowledge-base' ? `${resource}/${id}/version` : `${resource}/${id}/version/current`);
        setVersionText(response.data);
      } catch (error) {
        console.error('Ошибка при запросе:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVersion();
  }, [dataProvider, id]);

  const onSubmit = (data: any) => {
    update(
      `${resource}/${id}/version/new`,
      {id, data: {text: data.text || ''}},
      {
        onSuccess: () => {
          notify("Материал обновлен", {type: "success"});
          refresh();
        },
        onError: () => {
          notify("Ошибка при обновлении материала", {type: "error"});
        },
      }
    )
  }

  if (loading) return <CircularProgress/>;

  return (
    <Form disabled={disabled} onSubmit={onSubmit} values={versionText} defaultValues={versionText}>
      <Stack spacing={2}>
        <RichEditorControl name={'text'} disabled={disabled}/>
        <LoadingButton loading={isPending} type={'submit'} disabled={disabled} variant={'contained'}
                       sx={{maxWidth: 'max-content'}}>
          Сохранить
        </LoadingButton>
      </Stack>
    </Form>
  )
})

export default Editor;
