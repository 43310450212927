import {useNotify, useRefresh, useResourceContext, useUpdate} from "react-admin";
import Modal from "components/Modal";
import {Box, Button, Stack, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import {Material, MaterialVersion} from "components/material/types";

interface SetVersionModalProps {
  isOpen: boolean;
  onClose: () => void;
  version: MaterialVersion
  material: Material
}

const SetVersionModal = ({isOpen, onClose, version, material}: SetVersionModalProps) => {
  const resource = useResourceContext();
  const [update, {isPending}] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSetVersion = () => {
    update(
      `${resource}/${version.materialId}/version/set`,
      {id: version.materialId, data: {versionId: version.id}},
      {
        onSuccess: () => {
          notify("Версия восстановлена", {type: "success"});
          refresh();
          onClose()
        },
        onError: (error, variables, context) => {
          notify("Ошибка при восстановлении версии", {type: "error"});
        },
      }
    )
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack spacing={2}>
        <Typography variant="h6" mb={2}>
          Восстановить версию
        </Typography>
        <Typography>
          {`Вы уверены, что хотите восстановить версию ${version.order} материала "${material.title}"?`}
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <LoadingButton loading={isPending} variant="contained" color="primary" sx={{ml: 2}}
                         onClick={handleSetVersion}>
            Восстановить
          </LoadingButton>
        </Box>
      </Stack>
    </Modal>
  )
}

export default SetVersionModal
