export enum MATERIAL_TYPE {
  MATERIAL = 'MATERIAL',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE'
}

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: string;
  email: string;
  is_blocked: boolean;
  permissions: Permission[];
}

export interface Permission {
  id: string;
  title: string;
  action: PermissionAction;
}

export enum ResourceNameEnum {
  KnowledgeBase = "knowledge-base",
  Material = "material",
  MaterialCategory = "material-category",
  Question = "question",
  QuestionCategory = "question-category",
  User = "user",
  Category = "category",
}

export type ResourceName = ResourceNameEnum;

export enum PermissionAction {
  KnowledgeBaseManagement = "knowledge_base_management",
  KnowledgeBaseUpdate = "knowledge_base_update",
  MaterialManagement = "material_management",
  MaterialUpdate = "material_update",
  QuestionManagement = "question_management",
  QuestionUpdate = "question_update",
  UserAdministration = "user_administration",
}
