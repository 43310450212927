import {Create, useGetOne} from "react-admin";
import {Box, Chip, CircularProgress, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useParams} from "react-router-dom";
import UserPermissions from "./components/UserPermissions";
import UserForm from "./components/UserForm";
import UserMenu from "resources/users/create/components/UserMenu";
import Edit from "components/custom/Edit";
import useSetTabTitle from "../../../hooks/useSetTabTitle";

const UserPage = () => {
  const params = useParams();
  const {id} = params;

  const mode = id ? "edit" : "create"; // Определяем режим страницы

  const {data: record, isLoading} = useGetOne("user", {id});

  useSetTabTitle(`Пользователь ${record?.name || ''}`)

  if (isLoading) return <CircularProgress/>;

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        <Grid size={{xs: 12}}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={"h4"}>
              {`Пользователь ${record?.name || ''}`}
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              {record?.is_blocked && (
                <Typography color="error" mt={2}>
                  <Chip label="Заблокирован" color="error"/>
                </Typography>
              )}
              {record && <UserMenu user={record}/>}
            </Stack>
          </Stack>
        </Grid>
        <Grid size={{xs: 12, md: 6}}>
          <Box flex={1} p={2} border={1} borderColor="grey.300" borderRadius={2}>
            <UserPermissions
              userPermissions={record?.permissions || []}
            />
          </Box>
        </Grid>
        <Grid size={{xs: 12, md: 6}}>
          <Box flex={1} p={2} border={1} borderColor="grey.300" borderRadius={2}>
            <UserForm
              user={record}
              mode={mode}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export const UserEdit = () => {
  return (
    <Edit>
      <UserPage/>
    </Edit>
  )
}

export const UserCreate = () => {
  return (
    <Create title={'Создать пользователя'}>
      <UserPage/>
    </Create>
  )
}

