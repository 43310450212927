import {Stack} from "@mui/material";
import RichEditorControl from "components/RichEditorControl";
import {
  ArrayInput,
  AutocompleteInput,
  Form,
  FormDataConsumer,
  ReferenceInput,
  required,
  SimpleFormIterator,
  TextInput,
  useCanAccess,
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
  useUpdate
} from "react-admin";
import {Question} from "resources/question/view/types";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import {validateSpace} from "utils/index";

interface AnswerFormProps {
  question: Question;
}

const getSearchParamsFromHash = () => {
  const hash = window.location.hash;
  const queryIndex = hash.indexOf('?');
  if (queryIndex === -1) return new URLSearchParams();

  const queryString = hash.slice(queryIndex + 1);
  return new URLSearchParams(queryString);
};

const AnswerForm = ({question}: AnswerFormProps) => {
  const [update, {isPending: isUpdating}] = useUpdate();
  const [create, {isPending: isCreating}] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect()

  const searchParams = getSearchParamsFromHash();

  const categoryId = searchParams.get('categoryId');
  const tagId = searchParams.get('tagId');

  const {canAccess: canUpdate} = useCanAccess({
    action: 'update',
    resource: 'question',
  });

  const loading = isCreating || isUpdating;

  const onSubmit = (data: any) => {
    if (question) {
      update(
        `question/${question.id}`,
        {
          id: question.id,
          data: {
            questionText: data.questionText,
            answerText: data.answerText,
            tagsIds: data.tags.map((tag: { tagId: string; categoryId: string }) => tag.tagId),
          }
        },
        {
          onSuccess: () => {
            notify("Ответ обновлен", {type: "success"});
            refresh();
          },
          onError: () => {
            notify("Ошибка при обновлении ответа", {type: "error"});
          },
        }
      )
    } else {
      create(
        'question',
        {
          data: {
            questionText: data.questionText,
            answerText: data.answerText,
            tagsIds: data.tags.map((tag: { tagId: string; categoryId: string }) => tag.tagId),
          }
        },
        {
          onSuccess: (res) => {
            notify("Ответ создан", {type: "success"});
            refresh();
            redirect('show', 'question', res.id)
          },
          onError: () => {
            notify("Ошибка при создании ответа", {type: "error"});
          },
        }
      )
    }
  }

  const defaultValues = question
    ? {
      ...question,
      tags: question?.tags?.map(tag => ({categoryId: tag.tag?.questionCategoryId, tagId: tag.tag?.id}))
    }
    : categoryId && tagId
      ? {
        tags: [{categoryId, tagId}]
      }
      : undefined;

  return <Form onSubmit={onSubmit} defaultValues={defaultValues}>
    <Stack spacing={2} p={2}>
      <TextInput source={'questionText'} label={'Текст вопроса'} minRows={3}
                 validate={[required(), validateSpace]}
                 disabled={!canUpdate}/>
      <RichEditorControl name={'answerText'} rules={required()} disabled={!canUpdate}/>
      <ArrayInput
        sx={{
          '& .RaSimpleFormIterator-form': {
            width: '50%'
          }
        }}
        source="tags" label={""} validate={required()}>
        <SimpleFormIterator disabled={!canUpdate}>
          <ReferenceInput
            reference={'question-category'}
            source="categoryId"
            optionText="title"
            emptyText="Все категории"
            alwaysOn
          >
            <AutocompleteInput
              disabled={!canUpdate}
              filterToQuery={(searchText) => ({category: searchText})}
              label="Категория" size={'small'}/>
          </ReferenceInput>
          <FormDataConsumer>
            {({
                formData, // The whole RichEditorControl data
                scopedFormData, // The data for this item of the ArrayInput
              }) => {
              return scopedFormData && scopedFormData.categoryId ? (
                <ReferenceInput
                  disabled={!scopedFormData.categoryId}
                  filter={{questionCategoryId: scopedFormData.categoryId}}
                  source="tagId"
                  reference={'tag'}
                  optionText="title"
                  emptyText="Все теги"
                >
                  <AutocompleteInput
                    disabled={!canUpdate || !scopedFormData.categoryId}
                    filterToQuery={(searchText) => ({title: searchText})}
                    label="Тег" size={'small'}/>
                </ReferenceInput>
              ) : null
            }
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <LoadingButton loading={loading} type={'submit'}
                     disabled={!canUpdate}
                     variant={'contained'}
                     sx={{maxWidth: 'max-content'}}>
        Сохранить
      </LoadingButton>
    </Stack>
  </Form>
}

export default AnswerForm;
