import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Question} from "resources/question/view/types";
import DeleteQuestionModal from "resources/question/view/components/modals/DeleteQuestionModal";
import HideQuestionModal from "resources/question/view/components/modals/HideQuestionModal";

interface QuestionActionsProps {
  question: Question;
}

const QuestionActions = ({question}: QuestionActionsProps) => {

  const [openHideModal, setOpenHideModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOption1 = () => {
    setOpenHideModal(true);
    handleCloseMenu();
  };

  const handleOption2 = () => {
    setOpenDeleteModal(true);
    handleCloseMenu();
  };

  const onCloseHideModal = () => setOpenHideModal(false);
  const onCloseDeleteModal = () => setOpenDeleteModal(false);


  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleOption1}>{question?.isHidden ? 'Опубликовать вопрос' : 'Скрыть вопрос'}</MenuItem>
        <MenuItem onClick={handleOption2}>Удалить вопрос</MenuItem>
      </Menu>

      {openHideModal && (<HideQuestionModal isOpen={openHideModal} onClose={onCloseHideModal} question={question}/>)}
      {openDeleteModal && (
        <DeleteQuestionModal isOpen={openDeleteModal} onClose={onCloseDeleteModal} question={question}/>)}
    </>
  );
};

export default QuestionActions;
