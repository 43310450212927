import React, {useState} from "react";
import {Box, Stack, Typography} from "@mui/material";
import {CreateUserResponse, User} from "../types";
import {
  DateInput,
  Form,
  required,
  TextInput,
  useCreate,
  useGetIdentity,
  useNotify,
  useRedirect,
  useUpdate
} from "react-admin";
import UserAccessModal from "./UserAccessModal";
import LoadingButton from "@mui/lab/LoadingButton";
import {validateEmail, validateSpace} from "utils/index";
import {setForceRefreshIdentity} from "../../../../config/state";

interface UserFormProps {
  user: Partial<User>;
  mode: 'create' | 'edit' | 'show';
}

const UserForm: React.FC<UserFormProps> = ({user, mode}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [accessData, setAccessData] = useState<{ email: string; password: string; } | null>(null);

  const {refetch, data: currentUser} = useGetIdentity();

  const notify = useNotify();
  const redirect = useRedirect();

  const [create, {isPending: isCreating}] = useCreate<CreateUserResponse>();
  const [update, {isPending: isUpdating}] = useUpdate<User>();

  const onSubmit = (data: any) => {
    if (mode === "create") {
      create(
        "user",
        {data},
        {
          onSuccess: (res) => {
            notify("Пользователь создан", {type: "success"});
            setAccessData({email: res.email, password: res.password});
            setModalOpen(true)
            redirect('show', 'user', res.id)
          },
          onError: () => notify("Ошибка при создании пользователя", {type: "error"}),
        }
      );
    } else if (mode === "edit") {
      if (!user.id) return;
      update(
        `user/${user.id}`,
        {id: user.id, data: {name: data.name, email: data.email}},
        {
          onSuccess: () => {
            if (currentUser?.id === user.id) {
              setForceRefreshIdentity(true);
              refetch()
            }
            notify("Пользователь обновлен", {type: "success"});
          },
          onError: () => notify("Ошибка при обновлении пользователя", {type: "error"}),
        }
      );
    }
  }

  const onCloseUserAccessModal = () => {
    setModalOpen(false);
    setAccessData(null)
  }

  return (
    <Box>
      <Form defaultValues={user} onSubmit={onSubmit}>
        <Stack spacing={2} alignItems={'flex-start'}>
          <Typography variant="h6" mb={2}>
            Пользователь
          </Typography>
          <TextInput
            source={"name"}
            label="ФИО"
            size={'small'}
            validate={[required(), validateSpace]}
            fullWidth
          />
          <TextInput
            label="Email"
            source={"email"}
            size={'small'}
            validate={[required(), validateEmail]}
            fullWidth
          />
          <DateInput
            label="Дата создания"
            source={"createdAt"}
            size={'small'}
            disabled
            fullWidth
          />
          <DateInput
            label="Дата редактирования"
            source={"updatedAt"}
            size={'small'}
            disabled
            fullWidth
          />
          <LoadingButton
            loading={isUpdating || isCreating}
            variant="contained"
            color="primary"
            sx={{marginTop: 2}}
            type={'submit'}
          >
            Сохранить
          </LoadingButton>
        </Stack>
      </Form>
      {
        accessData && isModalOpen && <UserAccessModal open={isModalOpen}
                                                      onClose={onCloseUserAccessModal}
                                                      email={accessData?.email}
                                                      password={accessData?.password}/>
      }
    </Box>
  );
};

export default UserForm;
