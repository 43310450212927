import {Button} from "@mui/material";
import {useState} from "react";
import EditSubcategoryModal from "resources/materialCategoryList/list/modals/EditSubcategoryModal";

interface CreateSubcategoryButtonProps {
  category?: { id: string; title: string };
}

const CreateSubcategoryButton = ({category}: CreateSubcategoryButtonProps) => {
  const [openCreateSubcategoryModal, setOpenCreateSubcategoryModal] = useState<boolean>(false);
  const handleOpenCreateSubcategoryModal = () => setOpenCreateSubcategoryModal(true);
  const handleCloseCreateSubcategoryModal = () => setOpenCreateSubcategoryModal(false);
  return (
    <>
      <Button onClick={handleOpenCreateSubcategoryModal} sx={{width: 'fit-content'}}>
        Создать подкатегорию
      </Button>
      {openCreateSubcategoryModal && <EditSubcategoryModal
          open={openCreateSubcategoryModal}
          onClose={handleCloseCreateSubcategoryModal}
          category={category}
      />}
    </>
  )
}

export default CreateSubcategoryButton;
