import React, {useState} from "react";
import {Card, IconButton, Menu, MenuItem, Stack, Typography,} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditSubcategoryModal from "../modals/EditSubcategoryModal";
import DeleteEntityModal from "components/category/DeleteEntityModal";

const SubcategoryCard = ({item, category}: {
  item: { id: string; title: string };
  category: { id: string; title: string }
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleEdit = () => {
    setEditModalOpen(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
    handleMenuClose();
  };

  return (
    <Card sx={{px: 2, py: 1.5}}>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Typography variant={'h6'}>{item.title}</Typography>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon/>
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleEdit}>Редактировать</MenuItem>
          <MenuItem onClick={handleDelete}>Удалить</MenuItem>
        </Menu>
      </Stack>

      {
        isEditModalOpen && (
          <EditSubcategoryModal
            open={isEditModalOpen}
            onClose={() => setEditModalOpen(false)}
            subcategory={item}
            category={category}
          />
        )
      }

      {
        isDeleteModalOpen && (
          <DeleteEntityModal
            entityText={{
              title: "Удалить подкатегорию",
              success: "Подкатегория удалена",
              error: "Ошибка при удалении подкатегории"
            }}
            resource={'subcategory'}
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            entity={item}
          />
        )
      }
    </Card>
  );
};

export default SubcategoryCard;
