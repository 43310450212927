import MaterialListTemplate from "components/material/list/MaterialListTemplate";
import useSetTabTitle from "hooks/useSetTabTitle";

const MaterialList = () => {
  useSetTabTitle("Материалы");
  return (
    <MaterialListTemplate/>
  );
};

export default MaterialList;
