import {Edit as RAEdit, EditProps as RAEditProps} from 'react-admin'

interface EditProps extends RAEditProps {

}

const Edit = ({children, ...props}: EditProps) => {
  return (
    <RAEdit
      actions={false}
      sx={{
        '& .MuiPaper-root': {
          boxShadow: 'none',
        }
      }}
      {...props}
    >
      {children}
    </RAEdit>
  )
}

export default Edit
