import {List} from "react-admin";
import CategoryActions from "./components/CategoryActions";
import categoryFilter from "./components/CategoryFilter";
import CategoryGrid from "components/category/CategoryGrid";
import CategoryCard from "./components/CategoryCard";
import FilterTemplate from "components/FilterTemplate";
import useSetTabTitle from "../../../hooks/useSetTabTitle";

const MaterialCategoryList = () => {
  useSetTabTitle("Категории материалов");
  return (
    <List
      resource={'category'}
      empty={<p>Категории материалов не найдены</p>}
      title="Категории материалов"
      actions={<CategoryActions/>}
    >
      <FilterTemplate filters={categoryFilter}/>
      <CategoryGrid Card={CategoryCard}/>
    </List>
  )
};

export default MaterialCategoryList
