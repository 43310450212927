import React from "react";
import {Box, Button, Typography} from "@mui/material";
import Modal from "components/Modal";
import {useNotify, useRefresh, useUpdate} from "react-admin";
import {User} from "resources/users/create/types";

interface BlockUserModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
}

const BlockUserModal: React.FC<BlockUserModalProps> = ({open, onClose, user}) => {

  const [update] = useUpdate<User>();
  const refresh = useRefresh()
  const notify = useNotify();

  const onUpdate = () => {
    update(
      `user/${user.id}/block-or-unblock`,
      {id: user.id, data: {...user, is_blocked: !user.is_blocked}},
      {
        onSuccess: (res: User) => {
          const {is_blocked} = res;
          notify(is_blocked ? "Пользователь заблокирован" : "Пользователь разблокирован", {type: "info"});
          refresh()
          onClose()
        },
        onError: (err) => {
          notify("Не удалось заблокировать пользователя", {type: "error"})
        },
      })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Typography variant="h6" mb={2}>
          {user.is_blocked ? "Разблокировать пользователя" : "Заблокировать пользователя"}
        </Typography>
        <Typography mb={2}>
          Вы уверены, что хотите {user.is_blocked ? "разблокировать" : "заблокировать"} пользователя?
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Отмена</Button>
          <Button
            variant="contained"
            color={user.is_blocked ? "success" : "error"}
            sx={{ml: 2}}
            onClick={() => {
              onUpdate()
            }}
          >
            {user.is_blocked ? "Разблокировать" : "Заблокировать"}
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default BlockUserModal;
