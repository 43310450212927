import {defaultTheme} from 'react-admin';
import palette from "./palette";
import components from "./components";
import typography from "./typography";

const primaryColor = "#FF424D";


const theme = {
  ...defaultTheme,
  palette,
  components,
  typography
}

export default theme
