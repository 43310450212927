import {
  ArrayInput,
  FileField,
  FileInput,
  Form,
  required,
  SimpleFormIterator,
  TextInput,
  useCanAccess,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import * as React from "react";
import {Stack, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {FileFormValue, MaterialFileResponse, PreparedFormFileValue} from "../FileAttachmentForm/types";
import useFileHandlers from "../FileAttachmentForm/useFileHandlers";
import {ResourceNameEnum} from "types/index";
import {validateSpace} from "utils/index";


const FileAttachmentForm = () => {
  const record = useRecordContext();
  const resource = useResourceContext() as ResourceNameEnum.KnowledgeBase || ResourceNameEnum.Material;

  const {canAccess: canUpdate} = useCanAccess({
    resource,
    action: 'update'
  })

  const files: PreparedFormFileValue[] = record?.files?.map((f: MaterialFileResponse) => ({
    id: f.id,
    name: f.name,
    file: {
      title: f.file.name,
      src: f.file.url,
    }
  }))

  const {loading, handleSubmit} = useFileHandlers();

  const onSubmit = (data: { files: FileFormValue[] }) => handleSubmit(data, files);

  return (
    // @ts-ignore
    <Form onSubmit={onSubmit} defaultValues={{files}}>
      <Stack spacing={1} p={2}>
        <Typography>Файлы</Typography>
        <ArrayInput source="files" label={""} disabled={!canUpdate}>
          <SimpleFormIterator
            disabled={!canUpdate}
            sx={{
              '& .RaSimpleFormIterator-line': {
                mb: 2.5
              }
            }}>
            <TextInput source={'name'} label={'Название документа'} size={'small'}
                       validate={[required(), validateSpace]}
                       disabled={!canUpdate}/>
            <FileInput source="file" multiple={false} label={'Файл'}
                       disabled={!canUpdate}
                       validate={required()}
                       sx={{
                         '& .RaFileInput-dropZone': {
                           backgroundColor: 'grey.100',
                           cursor: 'pointer',
                           '& > p': {
                             m: 1,
                           }
                         }
                       }}>
              <FileField source="src" title="title" label={'Файл'}/>
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
        <LoadingButton loading={loading} type={'submit'} variant={'contained'}
                       disabled={!canUpdate}
                       sx={{maxWidth: 'max-content'}}>
          Сохранить
        </LoadingButton>
      </Stack>
    </Form>
  )
}

export default FileAttachmentForm
